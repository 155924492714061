<template>
    <BModal
        id="testRules"
        ref="testRules"
        data-cy="testRules"
        hide-header-close
        no-close-on-esc
        no-close-on-backdrop
        static
        size="lg"
        modal-class="bar bar-primary"
        :title="isSingleRule ? 'Evaluate Rule' : 'Evaluate Rules'"
        visible
    >
        <div class="form-group">
            <label
                id="policyNumberLabel"
                class="form-control-label"
            >
                Policy Number
            </label>

            <input
                v-model="policyNumber"
                class="form-control"
                placeholder="enter a policy number..."
                type="text"
                data-cy="evaluatePolicyNumber"
            >
        </div>

        <template #modal-footer>
            <button
                type="button"
                class="btn btn-primary"
                @click="testRules"
            >
                Run
            </button>

            <button
                class="btn btn-secondary"
                data-cy="cancelButton"
                @click="hide"
            >
                Cancel
            </button>
        </template>

        <BRDDebugger
            v-model="debuggerIsVisible"
            :policy-version-id="$route.query.policyVersionId || ''"
            :run-id="$route.query.runId || ''"
        />
    </BModal>
</template>

<script>
    import BRDDebugger from '@imt/vue-business-rules-debugger/src/components/BRDDebugger.vue';
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import utils from '@imt/vue-toolbox/src/utils';
    import {mapActions} from 'vuex';

    export default {
        name: 'BRTestModeModal',
        components: {
            BRDDebugger
        },
        mixins: [
            toastsMixin,
        ],
        data() {
            return {
                debuggerIsVisible: false,
                policyNumber: null,
            };
        },
        computed: {
            isSingleRule() {
                return !!this.$route.params.ruleId;
            }
        },
        methods: {
            async testRules() {
                try {
                    const ruleId = this.isSingleRule ? this.$route.params.ruleId : null,
                          response = await this.testBusinessRules({
                              ruleId: ruleId,
                              policyNumber: this.policyNumber
                          });

                    this.$router.replace({query: {
                        ...this.$route.query,
                        policyVersionId: response.policyVersionId,
                        runId: response.id
                    }}).catch(error => {
                        utils.console.log(error);
                    });

                    this.debuggerIsVisible = true;
                } catch (error) {
                    this.error(error?.response?.data?.errors ?
                        error.response.data.errors[0].detail :
                        'There was an error when attempting to run test mode.');

                    utils.console.log(error);
                }
            },
            hide() {
                this.$bvModal.hide('testRules');
                this.$router.back();
            },
            ...mapActions([
                'testBusinessRules'
            ])
        },
    };
</script>
