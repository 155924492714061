<template>
    <BModal
        id="advancedSearchModal"
        ref="advancedSearchModal"
        data-cy="advancedSearchModal"
        modal-class="bar bar-primary"
        size="xl"
        static
        title="Advanced Search"
        visible
        @hidden="hide"
    >
        <template #modal-header-close>
            <FontAwesomeIcon
                icon="times"
                size="lg"
            />
        </template>

        <div class="col-12">
            <div class="form-group">
                <label class="form-control-label">
                    Title
                </label>

                <input
                    v-model="title"
                    class="form-control"
                    placeholder="enter a title..."
                    type="text"
                    data-cy="advancedRuleTitle"
                >
            </div>

            <div class="form-group">
                <label class="form-control-label">
                    Description
                </label>

                <input
                    v-model="description"
                    class="form-control"
                    placeholder="enter a description..."
                    type="text"
                    data-cy="advancedDescription"
                >
            </div>

            <div class="form-group">
                <label class="form-control-label">
                    Internal Notes
                </label>

                <input
                    v-model="internalNotes"
                    class="form-control"
                    placeholder="enter internal notes..."
                    type="text"
                    data-cy="advancedInternalNotes"
                >
            </div>

            <div class="form-group">
                <label class="form-control-label">
                    Help Wording
                </label>

                <input
                    v-model="helpWordingText"
                    class="form-control"
                    placeholder="enter help wording..."
                    type="text"
                    data-cy="advancedHelpWording"
                >
            </div>

            <div class="form-group">
                <label class="form-control-label">
                    Policy Effective Date Range
                </label>

                <div class="input-group effective-dates">
                    <BFormDatepicker
                        id="effectiveDateBegin"
                        v-model="effectiveDateBegin"
                        data-cy="advancedEffectiveBeginDate"
                        class="input-group mr-1"
                        placeholder="mm/dd/yy"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    />

                    <div class="date-divider text-muted mr-1">
                        <span>to</span>
                    </div>

                    <BFormDatepicker
                        id="effectiveDateEnd"
                        v-model="effectiveDateEnd"
                        data-cy="advancedEffectiveEndDate"
                        class="input-group"
                        placeholder="mm/dd/yy"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    />
                </div>
            </div>

            <IMTUISelect
                id="transaction-type"
                v-model="transactionType"
                data-cy="transactionType"
                class="transaction-type-select"
                label="Transaction Type"
                :multiple="true"
                :options="transactionTypes"
            />

            <div class="form-group advanced-search-status">
                <label class="form-control-label">
                    Status
                </label>

                <Multiselect
                    v-model="statusType"
                    label="label"
                    :multiple="true"
                    :options="statusTypes"
                    placeholder="begin typing a status..."
                    track-by="value"
                />
            </div>

            <div class="form-group text-muted">
                <label class="form-control-label">
                    Rule Uses
                </label>

                <input
                    class="form-control"
                    placeholder="not yet implemented..."
                    type="text"
                    disabled="disabled"
                >
            </div>

            <div class="form-group advanced-search-actions">
                <label class="form-control-label">
                    Performs Action
                </label>

                <Multiselect
                    v-model="actionType"
                    label="label"
                    :multiple="true"
                    :options="actionTypes"
                    placeholder="begin typing an action..."
                    track-by="value"
                />
            </div>

            <IMTUISelect
                id="rule-type"
                v-model="ruleType"
                data-cy="ruleType"
                class="rule-type-select"
                label="Rule Type"
                :multiple="true"
                :options="ruleTypes"
            />

            <IMTUISelect
                id="underwriting-fix"
                v-model="underwritingFix"
                data-cy="underwritingFix"
                :deselection-allowed="true"
                label="Requires Underwriting Fix"
                name="underwritingFix"
                :options="yesNoOptions"
            />

            <transition
                name="fade"
                mode="out-in"
            >
                <PopoverSelect
                    v-if="pageType"
                    id="advancedSearchPageSelect"
                    ref="pageSelectPopover"
                    v-model="pageId"
                    data-cy="pageSelect"
                    :label="pageSelectLabel"
                    :options="pageOptions"
                    :placeholder="pageSelectPlaceholder"
                />
            </transition>
        </div>

        <template #modal-footer>
            <button
                type="button"
                class="btn btn-primary btn-search"
                @click="search"
            >
                <FontAwesomeIcon
                    icon="search"
                    class="mr-1"
                />
                Search
            </button>

            <button
                type="button"
                class="btn btn-secondary btn-close"
                @click="$ga.event('Advanced Search Modal', 'Cancel Advanced Search', 'action'); $bvModal.hide('advancedSearchModal')"
            >
                Cancel
            </button>

            <button
                type="button"
                class="btn btn-secondary btn-reset"
                @click="reset"
            >
                Reset
            </button>
        </template>
    </BModal>
</template>

<script>
    import IMTUISelect from '@imt/vue-imt-ui-select/src/components/IMTUISelect.vue';
    import PopoverSelect from '@imt/vue-popover-select/src/components/PopoverSelect.vue';
    import utils from '@imt/vue-toolbox/src/utils';
    import versioningMixin from '@imt/vue-versioning/src/mixins/versioning';
    import dayjs from 'dayjs';
    import isEmpty from 'lodash/isEmpty';
    import Multiselect from 'vue-multiselect';
    import {mapActions, mapMutations, mapState} from 'vuex';

    import {tooltips} from '@/lang';
    import {dropdownUtils} from '@/utilities';

    let initialValues = () => {
        return {
            description: '',
            internalNotes: '',
            helpWordingText: '',
            pageId: '',
            pageOptions: [],
            pageType: '',
            ruleType: [],
            title: '',
            transactionType: [],
            underwritingFix: null,
        };
    };

    // @group Modals
    export default {
        name: 'BRAdvancedSearchModal',
        components: {
            IMTUISelect,
            Multiselect,
            PopoverSelect,
        },
        mixins: [versioningMixin],
        data() {
            return {
                actionType: [],
                effectiveDateBegin: '',
                effectiveDateEnd: '',
                statusType: [],
                tooltipWording: tooltips.en,
                ...initialValues(),
            };
        },
        computed: {
            actionTypes: dropdownUtils.actionTypes,
            pageSelectLabel: dropdownUtils.pageSelectLabel,
            pageSelectPlaceholder: dropdownUtils.pageSelectPlaceholder,
            ruleTypes: dropdownUtils.ruleTypes,
            statusTypes: dropdownUtils.statusTypes,
            transactionTypes: dropdownUtils.transactionTypes,
            yesNoOptions: dropdownUtils.yesNoOptions,
            ...mapState([
                'currentPolicySystem',
                'searchFields'
            ]),
        },
        watch: {
            async currentPolicySystem(system) {
                if (system.id && this.pageType) {
                    await this.loadPageOptions(this.pageType);
                }
            },
            async pageType(type) {
                if (this.currentPolicySystem) {
                    await this.loadPageOptions(type);
                }
            },
            searchFields() {
                this.setFieldValues();
            },
            ruleType(newValue) {
                if (newValue.includes('page_display') || newValue.includes('page_validation')) {
                    this.pageType = 'page';
                } else {
                    this.pageId = '';
                    this.pageType = '';
                }
            },
        },
        mounted() {
            this.setFieldValues();
        },
        methods: {
            async hide() {
                this.reset();
                await this.$router.push({
                    name: 'admin.rules.list',
                    query: {...this.$route.query, ['page']: '1'}
                });

                try {
                    await this.fetchRules(this.$route.query);
                } catch (error) /* istanbul ignore next */ {
                    this.error('Rules were not found or do not exist.', 'Rules Not Found');
                    utils.console.log(error);
                }
            },
            reset() {
                this.$ga.event('Advanced Search Modal', 'Reset', 'action');
                this.actionType = [];
                this.effectiveDateBegin = '';
                this.effectiveDateEnd = '';

                Object.assign(this.$data, initialValues());
                this.statusType = [];
            },
            async search() {
                let searchFields = {
                    title: this.title.trim(),
                    description: this.description.trim(),
                    policyEffectiveDate__gte: this.effectiveDateBegin ?
                        dayjs(this.effectiveDateBegin).format('YYYY-MM-DD') :
                        '',
                    policyEffectiveDate__lte: this.effectiveDateEnd ?
                        dayjs(this.effectiveDateEnd).format('YYYY-MM-DD') :
                        '',
                    internalNotes: this.internalNotes.trim(),
                    helpWordingText: this.helpWordingText.trim(),
                    pageId: this.pageId,
                    pageType: this.pageType,
                    ruleType: this.ruleType.join(','),
                    underwritingFix: this.underwritingFix !== null ? this.underwritingFix.toString() : '',
                    status: this.statusType.map(s => s.value).join(','),
                    transactionType: this.transactionType.join(','),
                    actionType: this.actionType.map(a => a.value).join(','),
                };
                this.$ga.event('Advanced Search Modal', 'Search', 'action', searchFields);

                this.CLEAR_SEARCH_FIELDS();

                for (const [key, value] of Object.entries(searchFields)) {
                    if (!isEmpty(value)) {
                        this.SET_SEARCH_FIELDS({
                            key: key,
                            value: value,
                        });

                        try {
                            this.$router.push({
                                query: {...this.$route.query, [key]: value}
                            });
                        } catch (e) {
                            utils.console.log(e);
                        }
                    }
                }

                this.$bvModal.hide('advancedSearchModal');
            },
            setFieldValues() {
                // Set search fields to match the search parameter values, if they exist.
                this.title = this.searchFields.title || '';
                this.description = this.searchFields.description || '';
                this.internalNotes = this.searchFields.internalNotes || '';
                this.helpWordingText = this.searchFields.helpWordingText || '';
                this.effectiveDateBegin = this.searchFields.policyEffectiveDate__gte || '';
                this.effectiveDateEnd = this.searchFields.policyEffectiveDate__lte || '';
                this.underwritingFix = null;
                if (this.searchFields.underwritingFix) {
                    this.underwritingFix = this.searchFields.underwritingFix === 'true';
                }

                if (this.searchFields.transactionType) {
                    this.transactionType = this.searchFields.transactionType.split(',');
                } else {
                    this.transactionType = [];
                }

                if (this.searchFields.ruleType) {
                    this.ruleType = this.searchFields.ruleType.split(',');
                } else {
                    this.ruleType = [];
                }

                this.pageType = this.searchFields.pageType || '';

                this.pageId = this.searchFields.pageId || '';
                if (this.pageId) {
                    this.$refs.pageSelectPopover.value = this.pageId;
                }

                this.actionType = dropdownUtils.findOptions(this.searchFields.actionType, this.actionTypes);
                this.statusType = dropdownUtils.findOptions(this.searchFields.status, this.statusTypes);
            },
            async loadPageOptions(type) {
                let options = await this.fetchPageOptions(type);

                this.pageOptions = options.map(option => {
                    const latestVersion = this.sortVersions(option)[0];

                    return {
                        label: latestVersion.title || option.title || `${option.number} ${latestVersion.screenName}`,
                        value: option.id,
                    };
                });
            },
            ...mapActions([
                'fetchRules',
                'fetchPageOptions',
            ]),
            ...mapMutations([
                'CLEAR_SEARCH_FIELDS',
                'SET_SEARCH_FIELDS'
            ])
        },
    };
</script>

<style
    lang="sass"
    scoped
>
    .date-divider
        align-items: center
        display: inline-flex
        justify-content: center
        min-width: 1%
        padding: 0.5rem 0.75rem
        user-select: none
        white-space: nowrap

    .fade-enter-active, .fade-leave-active
        transition: opacity .5s
    .fade-enter, .fade-leave-to
        opacity: 0
</style>
