import { render, staticRenderFns } from "./BRAdvancedSearchModal.vue?vue&type=template&id=380eb4ec&scoped=true"
import script from "./BRAdvancedSearchModal.vue?vue&type=script&lang=js"
export * from "./BRAdvancedSearchModal.vue?vue&type=script&lang=js"
import style0 from "./BRAdvancedSearchModal.vue?vue&type=style&index=0&id=380eb4ec&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "380eb4ec",
  null
  
)

export default component.exports